<script>
    import { page } from '$app/stores';
    import Button from './Button.svelte';
    import PageSection from './PageSection.svelte';
    import Prose from './Prose.svelte';
    import { logEvent } from './utils/logs';

   

    let className = '';
    export {className as class}

    const text = $page.data.hero_text;

    const handle_explore = () => {
        logEvent('hero-explore');
        document
            .querySelector('#course-1')
            .scrollIntoView({ behavior: 'smooth' });
    };
</script>

<PageSection fluid
    class="
        hero
        flex flex-col
        overflow-hidden
        {className}
    "
>
    <Prose
        lg
        class="
            hero-content
            flex flex-col items-center justify-end
            md:prose-h1:text-center
        "
    >
        {@html text}
        <p>
            <Button primary xxl on:click={handle_explore}
                >Explore Courses</Button
            >
        </p>
    </Prose>
</PageSection>

<style lang="postcss">


    @media (max-width: 767px) {
        :global(.hero) {
            padding-block: theme(spacing.4);
            position: relative;

            justify-content: center;
            align-items: center;

            &::after {
                content: '';
                position: absolute;
                border-radius: theme(borderRadius.2xl);
                z-index: 1;
                width: calc(100% - theme(spacing.8));
                height: calc(100% - theme(spacing.8));
                max-width: calc(theme(maxWidth.screen-xs) - theme(spacing.8));  

                background-image: conic-gradient( 
                    theme(colors.purple.500),
                    theme(colors.red.500),
                    theme(colors.yellow.500)
                );
                filter: blur(10px);
            }
        }

        :global(.hero-content) {
            background-color: theme(colors.black);
            border-radius: theme(borderRadius.2xl);
            padding: theme(spacing.8);
            position: relative;
            z-index: 2;          
            max-width: calc(theme(maxWidth.screen-xs) -  theme(spacing.8)); 
            align-items: center;
            justify-content: center;
        }
    }

    @media (min-width: theme(maxWidth.screen-sm) ) and (max-width: 767px) {
        :global(.hero::after),
        :global(.hero-content) {
            max-width: calc(theme(maxWidth.screen-sm) - 2 * theme(spacing.8)); 
        }
    }

    @media (min-width: theme(maxWidth.screen-md)) {
        :global(.hero) {

            padding-top: theme(spacing.48);

            background-image: url('/stars.svg');
            background-size: cover;
            background-position: center;
            background-color: theme(colors.black / 100%);
            background-repeat: no-repeat;

            position: relative;
            --dura: 30s;

            &::after {
                content: '';
                position: absolute;
                width: 1200px;
                aspect-ratio: 2/1;
                border-radius: 999px 999px 0 0;

                bottom: 0;
                left: 50%;

                transform: translateX(-50%);

                background-color: theme(colors.black / 100%);
                border: 3px solid theme(colors.blue.500 / 20%);
                z-index: 1;
            }

            &::before {
                content: '';
                position: absolute;
                border-radius: 9999px;
                z-index: 0;
                width: 1200px;
                height: 1200px;
                left: 50%;
                bottom: -95%;
                translate: -50% 0;

                background-image: conic-gradient( 
                    theme(colors.purple.500),
                    theme(colors.red.500),
                    theme(colors.yellow.500)
                );
                filter: blur(120px);

                animation: rotate 20s linear infinite;
            }

        }
        :global(.hero-content) {
            padding-top: theme(spacing.24);
            padding-bottom: theme(spacing.12);
            margin-inline: auto;

            max-width: theme(maxWidth.xl);
            position: relative;

            z-index: 2;
        }
    }


    @keyframes rotate {
        to {
            transform: rotate(360deg);
        }
    }

</style>
