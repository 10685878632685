<script>
    import { page } from "$app/stores";
    import Prose from "./Prose.svelte";


    let className = "";
    export { className as class };

    const text = $page.data.story_mission;

</script>


<Prose lg class="mx-auto {className}">
    {@html text}
</Prose>