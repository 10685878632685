<script>
    import PageLayout from '$lib/PageLayout.svelte';
    import { page } from '$app/stores';

    import CourseHero from '$lib/CourseHero.svelte';
    import Courses from '$lib/Courses.svelte';
    import StoryMission from '$lib/StoryMission.svelte';
    import Award from '$lib/Award.svelte';
    import Hero from '$lib/Hero.svelte';
    import Reviews2 from '$lib/Reviews2.svelte';
    import ScrollableSection from '$lib/ScrollableSection.svelte';
    import Worksheets from '$lib/Worksheets.svelte';
    import H2 from '$lib/H2.svelte';
    import PageSection from '$lib/PageSection.svelte';
    import Faq2 from '$lib/FAQ2.svelte';

    let worksheets = $page.data.worksheets;

    let courses_with_price = $page.data.courses.filter((c) => c.price);
    let courses_without_price = $page.data.courses.filter((c) => !c.price);


    // console.log($page.data.bundles);

</script>

<PageLayout>
    <Hero />

    <ScrollableSection
        class="container mx-auto py-4 md:my-8 "
        content_class="flex items-center justify-start "
    >
        <Award class="w-[280px] flex-shrink-0 snap-start self-start mt-8 mr-4" />
        <Reviews2
            reviews={$page.data.reviews}
            class="flex items-start"
            review_class="w-[300px] flex-shrink-0 snap-start px-4 box-content last:pr-16"
        />
    </ScrollableSection>


    <div id="course-1" />

    {#each courses_with_price as course}
        <CourseHero
            class="container mx-auto px-4 md:px-8 mb-12 lg:mt-24"
            course={course}
            title={course.title}
            text={course.lead}
            playback_id={course.trailer_playback_id}
            thumbnail_time={course.trailer_thumbnail_time}
            feature="home-hero"
            header_tag="h2"
        />
    {/each}


    <PageSection class="my-12 lg:my-24">
        <H2 lg class="mb-4">Worksheets & Lesson Plans</H2>

        <ScrollableSection
            class="negate-page-section-px"
            content_class="flex gap-4 md:gap-6 flex-nowrap"
        >
            <Worksheets
                {worksheets}
                h_level={3}
                class="snap-center min-w-[280px]"
            />
        </ScrollableSection>
    </PageSection>

    <PageSection class="my-12">
        <StoryMission />
    </PageSection>

    <PageSection class="my-12">
        <!-- <Faq class="mx-auto" /> -->
         <Faq2 class="mx-auto" />
    </PageSection>

    <PageSection class="my-12 lg:my-24">
        <H2 lg class="mb-4">What's next</H2>

        <ScrollableSection
            class="negate-page-section-px"
            content_class="flex gap-4 md:gap-6 flex-nowrap"
        >
            <Courses
                courses={courses_without_price}
                class="snap-center min-w-[280px]"
            />
        </ScrollableSection>
    </PageSection>
</PageLayout>
