<script>
    let className = '';
    export { className as class };
</script>

<div class="award flex flex-nowrap gap-4 max-w-xs justify-center {className}">
    <div class="flex flex-col text-center">
        <div class="text-xl font-bold text-white">Best Life Skills<br> Education Website</div>
        <div>by Homeschool.com</div>
    </div>
</div>


<style>
    .award:before, .award:after {
        content: "";
        display: block;
        width: 36px;
        height: 58px;        
        background: url(/branch.svg) no-repeat 0 0;
    }

    .award:after {
        transform: scaleX(-1);
    }

</style>